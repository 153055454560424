export const createStudentSteps = (component, isMobile) => [
    {
        popover: {
          side: 'top',
          align: 'start',
          nextBtnText: component.$t('tour.welcome.next'),
          title: component.$t('tour.welcome.title'),
          description: component.$t('tour.welcome.description'),
          showButtons: ["next", "close"]
        },
      },
      {
        element: '.search',
        popover: {
          side: "bottom",
          align: 'start',
          nextBtnText: component.$t('tour.common.next'),
          prevBtnText: component.$t('tour.common.previous'),
          showButtons: ["next", "close", "previous"],
          title: component.$t('tour.search.title'),
          description: component.$t('tour.search.description'),
          onNextClick: () => {

            if(isMobile){
              const topbarUserMenu = document.querySelector('.user-menu');
              if (topbarUserMenu) {
                topbarUserMenu.click();
              }
            }

            component.$refs.tourGuide.moveNext();
          },
        }
      },
      { 
        element: '.user-submenu',
        popover: {
          side: "top",
          align: 'start',
          title: component.$t('tour.profile.title'),
          nextBtnText: component.$t('tour.common.next'),
          prevBtnText: component.$t('tour.common.previous'),
          showButtons: ["next", "close", "previous"],
          description: component.$t('tour.profile.description'),
          onNextClick: () => {

            if(isMobile){
              const topbarUserMenu = document.querySelector('.user-menu');
              if (topbarUserMenu) {
                topbarUserMenu.click();
              }
            }
  
            component.$refs.tourGuide.moveNext();
          },
          onPrevClick: () => {

            if(isMobile){
              const topbarUserMenu = document.querySelector('.user-menu');
              if (topbarUserMenu) {
                topbarUserMenu.click();
              }
            }

            component.$refs.tourGuide.movePrevious();
          }
        }
      },
      {
        element: '#certificado',
        popover: {
          side: "right",
          align: 'start',
          title: component.$t('tour.certificates.title'),
          nextBtnText: component.$t('tour.common.next'),
          prevBtnText: component.$t('tour.common.previous'),
          showButtons: ["next", "close", "previous"],
          description: component.$t('tour.certificates.description'),
          onPrevClick: () => {
            const topbarUserMenu = document.querySelector('.user-menu');
            if (topbarUserMenu) {
              topbarUserMenu.click();
            }
            component.$refs.tourGuide.movePrevious();
          }
        },
      },
      {
        element: '#my-courses',
        popover: {
          nextBtnText: component.$t('tour.common.finish'),
          prevBtnText: component.$t('tour.common.previous'),
          showButtons: ["next", "close", "previous"],
          side: "right",
          align: 'start',
          title: component.$t('tour.courses.title'),
          description: component.$t('tour.courses.description'),
        }
      }
  ];