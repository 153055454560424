<template>
	<SimpleModalFeedback
		:title="$t('scheduled_maintenance.title')"
		:desc="$t('scheduled_maintenance.description')"
		:confirmBtnText="$t('scheduled_maintenance.confirmBtn')"
		@close="handleClose()"
		@confirm="handleClose()"
    :showCancelBtn="false"
    :noCloseOnBackdrop="true"
	>
		<TextPlay
      class="mb-3"
			:text="$t('scheduled_maintenance.description', { day: 20, month: 'Dezembro', year: '2024' })"
			color="var(--text-color)"
		/>
		<TextPlay
      class="mb-3"
			:text="$t('scheduled_maintenance.window', { startHour: '04:00', endHour: '06:00' })"
			color="var(--text-color)"
		/>

		<TextPlay
			v-html="$t('scheduled_maintenance.startDate', { startDate: '20/12/2024 ' })"
			color="var(--text-color)"
		/>
		<TextPlay
			v-html="$t('scheduled_maintenance.endDate', { endDate: '20/12/2024' })"
			color="var(--text-color)"
		/>
	</SimpleModalFeedback>
</template>

<script>
import Cookies from "js-cookie";
import SimpleModalFeedback from "@/modals/template/SimpleFeedback.vue";

export default {
	components: {
		SimpleModalFeedback,
	},
	methods: {
		handleClose() {
			Cookies.set("hideScheduledMaintenance", "true", { expires: 30 });
			this.$emit("close");
		},
	},
};
</script>
