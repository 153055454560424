<template>
	<div
		class="plan"
		:class="{ 'plan--recommended': isRecommended }"
	>
		<div
			v-if="isRecommended"
			class="plan__recommended"
		>
			<TextPlay
				size="sm"
				weight="bold"
				color="var(--backgroundcolor)"
				:text="$t('plans.recommended')"
			/>
		</div>
		<div class="plan__info">
			<TextPlay
				size="lg"
				weight="bold"
				color="var(--fontcolor)"
				:text="name"
			/>
			<DividerPlay
				mt="8px"
				mb="16px"
			/>
			<TextPlay
				size="xs"
				color="var(--text-color)"
				:text="getPlanDesc()"
			/>
		</div>

		<div class="plan__action">
			<TextPlay
				weight="bold"
				color="var(--fontcolor)"
				:text="value"
			/>
			<TextPlay
				v-if="value !== 'Grátis'"
				color="var(--text-color)"
				size="xs"
				:text="$t('plans.monthly_payment')"
			/>
			<button
				class="plan-btn"
				:class="{ 'plan-btn--disabled': isActive }"
				@click="handleOpenPlan()"
			>
				{{ isActive ? $t("plans.current_plan") : $t("plans.to_contract_plan") }}
			</button>
		</div>
	</div>
</template>

<script>
import SiteService from "@/services/resources/SiteService";
const serviceSite = SiteService.build();

export default {
	props: {
		name: String,
		value: String,
		isActive: Boolean,
		plan: Object,
		productId: [Number, String],
		planId: [Number, String],
		isRecommended: Boolean,
	},
	methods: {
		getPlanDesc() {
			const descMap = {
				1: this.$t("plans.3GB_desc"),
				2: this.$t("plans.100GB_desc"),
				3: this.$t("plans.200GB_desc"),
				4: this.$t("plans.300GB_desc"),
				5: this.$t("plans.500GB_desc"),
				6: this.$t("plans.1TB_desc"),
				7: this.$t("plans.2TB_desc"),
			};
			return descMap[this.planId];
		},
		async handleOpenPlan() {
			try {
				const resp = await serviceSite.read("/owner");

				window.open(
					`https://pay.voompcreators.${process.env.VUE_URL_DOMAIN}/${this.productId}?fn=${resp.name}&em=${resp.email}&ph=${resp.cellphone}&document=${resp.document_number}`,
					"_blank"
				);
			} catch (error) {
				this.$bvToast.toast(this.$t("delete_site_modal.error_to_load_plans_desc"), {
					title: this.$t("delete_site_modal.error_to_load_plans"),
					variant: "danger",
					autoHideDelay: 5000,
					appendToast: true,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.plan {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between !important;
	text-align: center;
	padding: 24px 16px;
	border-radius: 16px;
	background-color: var(--caption-bg-box);
	transition: all 0.3s;
	position: relative;
	height: 286px;

	&__recommended {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-60%);
		padding: 10px;
		background-color: var(--fontcolor);
		border-radius: 8px;
	}

	&:hover {
		[data-theme="dark"] & {
			background-color: var(--neutral-gray-700);
		}
		background-color: var(--neutral-gray-100);
	}

	&__action {
		margin-top: auto;

		.plan-btn {
			width: 131px;
			padding: 12px;
			margin-top: 16px;
			border: none;
			border-radius: 8px;
			background-color: var(--maincolor);
			color: white;
			cursor: pointer;
			transition: all 0.3s;
			font-weight: 600;

			&:hover {
				opacity: 0.8;
			}

			&--disabled {
				pointer-events: none;
				color: var(--neutral-gray-600);
				background-color: var(--neutral-gray-200);
			}
		}
	}

	&--recommended {
		border: solid 2px var(--fontcolor);
	}
}

@media screen and (max-width: 768px) {
	.plan {
		height: auto;
		gap: 16px;

		&__action {
			width: 100%;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-between;

			.plan-btn {
				margin-top: 0;
			}
		}
	}
}
</style>
