<template>
  <Modal id="modal-first-access-config" :visible="false" title="Configurar primeiro acesso"
    subtitle="Defina as informações que serão exibidas aos alunos quando eles acessarem sua plataforma."
    @hidden="hidden" @shown="shown" size="lg" class="root">
    <div class="content">
      <div class="contentText mb-4">
        <Editor :showColor="false" v-model="config.content" :onChange="onEditorChange"
          placeholder="Digite aqui a descrição do primeiro acesso..." />
      </div>
      <b-form-group class="embed-input mb-4" label="Embed link" label-for="descricao"
        description="Informe um link externo para ser incorporado no modal.">
        <b-form-input v-model="config.embed_link" name="embed_link" id="embed_link" placeholder="Seu link"
          autocomplete="link" v-validate="'required'" />
        <b-form-invalid-feedback :state="!warnings?.embed_link?.no_embeddable">
          Este link não pode ser incorporado. Para obter mais informações sobre a restrição, entre em contato com o
          administrador do link.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Como deseja exibir o modal?" label-for="descricao" class="fieldDisplay">
        <b-form-radio-group v-model="config.display" class="mb-3" value-field="item" text-field="name"
          disabled-field="notEnabled">
          <b-form-radio value="onetime">
            Exibir apenas uma única vez.
          </b-form-radio>
          <b-form-radio value="opt-out">
            Exibir em todos os acessos, até que o aluno opte por não exibir mais.
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <div class="footer">
        <ButtonPlay type="cancel" :text="'Cancelar'" @click="cancel()" />
        <ButtonPlay type="normal" :disabled="config?.content?.length <= 0" :text="'Salvar'" @click="submit()" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex"
import Editor from "@/components/Editor.vue";
import fp from "lodash/fp"

function initialState() {
  return {
    config: {
      display: "onetime",
      content: null,
      embed_link: null,
    },
    warnings: {},
    termosPainel: "texto",
    midiaTermos: null,
    midiaTermosUrl: null,
    mediaTypeLesson: "",
  }
}

export default {
  components: {
    Editor,
  },
  data: initialState,
  methods: {
    ...mapActions({
      actionFetchMetas: "config/actionFetchMetas",
      actionSaveSettings: "config/actionSaveSettings",
      actionCheckUrlEmbed: "app/actionCheckUrlEmbed"
    }),
    cancel() {
      this.$root.$emit(
        "bv::hide::modal",
        "modal-first-access-config",
        "#btnShow"
      );
    },
    async load() {
      const config = await this.actionFetchMetas({
        keys: ["first_access_config"]
      })
      this.config = JSON.parse(config["first_access_config"]) || {}
    },
    async submit() {
      const isValid = await this.validate()
      if (!isValid) {
        return
      }
      try {
        await this.actionSaveSettings({
          id: "first_access_config",
          value: JSON.stringify(this.config)
        });
        this.feedbackSuccess()
        this.$root.$emit(
          "bv::hide::modal",
          "modal-first-access-config",
          "#btnShow"
        );
      } catch (error) {
        this.feedbackError()
      }
    },
    setYoutubeEmbedUrl() {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = this.config.embed_link.match(regExp);
      const id = (match && match[7].length == 11) ? match[7] : false;
      if (id) {
        this.config.embed_link = `https://www.youtube.com/embed/${id}`
      }
    },
    setVimeoEmbedUrl() {
      var regExp = /^https:\/\/((vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = this.config.embed_link.match(regExp);
      const id = (match && match[7]) ? match[7] : false;
      if (id) {
        this.config.embed_link = `https://player.vimeo.com/video/${id}`
      }
    },
    async validate() {
      if (this.config.embed_link) {
        this.setYoutubeEmbedUrl()
        this.setVimeoEmbedUrl()
      }
      if (this.config.content && this.config.embed_link) {
        this.feedbackError("Você não pode utilizar texto e link ao mesmo tempo.")
        return false;
      }
      return await this.validateConfigEmbedLink()
    },
    feedbackSuccess(message = "Configurações salvas e recurso habilitado.") {
      this.$bvToast.toast(message, {
        title: "Recurso habilitado com sucesso",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    feedbackError(message = "Houve um erro de validação!") {
      this.$bvToast.toast(message, {
        title: "Erro ao habilitar recurso",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    async validateConfigEmbedLink() {
      if (!this.config.embed_link) {
        this.warnings = fp.omit("embed_link", this.warnings)
        return true
      }
      const { valid } = await this.actionCheckUrlEmbed({
        url: this.config.embed_link
      });
      if (!valid) {
        this.warnings = fp.set("embed_link.no_embeddable", !valid, this.warnings)
        this.feedbackError("Este site não pode ser incorporado.")
      }
      return valid
    },
    onEditorChange(value) {
      this.config.content = value
    },
    hidden() {
      Object.assign(this.$data, initialState());
    },
    shown() {
      this.load()
    }
  },
};
</script>

<style lang="scss">
.fieldDisplay {
  .bv-no-focus-ring {
    display: flex !important;
    flex-direction: column !important;
  }
  label {
    font-size: 16px !important;
  }
  .custom-control-label {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.embed-input {
  label {
    font-size: 16px !important;
  }
  input {
    border-radius: 8px !important;
    height: 48px !important;
  }
  small, .small {
    font-size: 14px !important;
  }
}
</style>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.contentText mb-4 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
</style>
