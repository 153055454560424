import Vue from "vue"
import Feedback from "./template/Feedback.vue"

Vue.component('Feedback', Feedback)

export { default as ModalGameficationNewMedal } from "./ModalGameficationNewMedal.vue"
export { default as ModalMemberCreate } from "./ModalMemberCreate.vue"
export { default as ModalExportMemberReport } from "./ModalExportMemberReport.vue"
export { default as ScheduledMaintenance } from "./ScheduledMaintenance.vue"
export { default as ModalTestWebhook } from "./ModalTestWebhook.vue"
export { default as ModalDeleteWebhook } from "./ModalDeleteWebhook.vue"