<template>
	<div
		class="general"
		data-anima="top"
	>
		<TextPlay
			class="general__title"
			:text="$t('settings.general.page_title')"
			color="var(--fontcolor)"
			size="2xl"
			weight="bold"
		/>

		<SettingsItem
			v-if="renderComponent"
			icon="star"
			:title="$t('settings.general.show_reviews')"
			:desc="$t('settings.general.show_reviews_desc')"
			param="rating_home"
			:value="rating_home"
			@change="update"
			topDivider
		/>

		<SettingsItem
			v-if="renderComponent"
			icon="message"
			:title="$t('settings.general.allow_comments')"
			:desc="$t('settings.general.allow_comments_desc')"
			param="coment_lesson"
			:value="coment_lesson"
			@change="update"
		>
			<SettingsItem
				v-if="coment_lesson"
				icon="message-search"
				:title="$t('settings.general.comments_moderation')"
				:value="comment_review"
				@change="update"
				param="comment_review"
				:desc="$t('settings.general.comments_moderation_desc')"
				pt="32px"
				pb="0px"
				:bottomDivider="false"
			/>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="link-21"
			:title="$t('settings.general.show_help_links')"
			:value="helper_data"
			@change="update"
			param="helper_data"
			:desc="$t('settings.general.show_help_links_desc')"
		>
			<SettingsItem
				v-if="helper_data"
				icon="link-square"
				:title="$t('settings.general.external_links')"
				:value="external_help"
				@change="update"
				param="external_help"
				:desc="$t('settings.general.external_links_desc')"
				pt="32px"
				pb="0px"
				:bottomDivider="false"
			>
				<div class="d-flex align-items-center mt-3">
					<InputPlay
						v-if="external_help"
						v-model="external_help_link"
						type="text"
						:placeholder="$t('settings.txt19')"
						w="280px"
					/>
					<ButtonPlay
						:disabled="external_help_link?.length <= 0"
						@click="saveText('external_help_link')"
						v-if="external_help"
						type="normal"
						:text="$t('settings.general.save')"
						class="ml-2"
						h="48px"
					/>
				</div>
			</SettingsItem>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="language"
			iconPackage="custom"
			:title="$t('settings.general.languages')"
			:value="null"
			:toggle="false"
			param="pais"
			:desc="$t('settings.general.languages_desc')"
		>
			<div class="mt-3 col-6 col-md-3 p-0">
				<Locales />
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="people"
			:title="$t('settings.general.students_limit')"
			:value="limit_devices_per_member"
			@change="update"
			param="limit_devices_per_member"
			:desc="$t('settings.general.students_limit_desc')"
		>
			<div
				class="d-flex align-items-center mt-3"
				v-if="limit_devices_per_member"
			>
				<b-form-input
					v-model="number_limit_devices_per_member"
					@change="updateNumberLimitAluno"
					style="width: 390px"
					variant="secondary"
					type="range"
					min="1"
					max="5"
					step="1"
				></b-form-input>
				<TextPlay
					class="ml-3"
					size="sm"
					color="var(--fontcolor)"
					v-html="$t('settings.general.students', { students: number_limit_devices_per_member })"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="messages"
			:title="$t('settings.general.add_jivochat')"
			:value="jivochat"
			@change="update"
			param="jivochat"
			:desc="$t('settings.general.add_jivochat_desc')"
		>
			<div
				class="mt-3 d-flex align-items-center"
				v-if="jivochat"
			>
				<InputPlay
					v-model="id_jivochat"
					type="text"
					:placeholder="$t('settings.general.id_jivochat')"
					w="400px"
				/>
				<ButtonPlay
					:disabled="id_jivochat?.length <= 0"
					@click="saveText('id_jivochat')"
					class="ml-2"
					type="normal"
					h="48px"
					:text="$t('settings.general.save')"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="whatsapp"
			iconPackage="custom"
			:title="$t('settings.general.add_wpp')"
			:value="whatsapp"
			@change="update"
			param="whatsapp"
			:desc="$t('settings.general.add_wpp_desc')"
		>
			<div v-if="whatsapp">
				<div class="whatsappConfig mt-3">
					<TextPlay
						class="mb-1"
						color="var(--fontcolor)"
						weight="semibold"
						:text="$t('settings.general.country_code')"
					/>
					<div class="mb-4 whatsappConfig-phone">
						<div class="whatsappConfig-phone__input">
							<VuePhoneNumberInput
								@update="onUpdate"
								default-country-code="BR"
								valid-color="#4CAF50"
								:translations="{
									countrySelectorLabel: $t('settings.txt33'),
									countrySelectorError: $t('settings.txt34'),
									phoneNumberLabel: $t('settings.txt35'),
									example: $t('settings.txt36'),
								}"
								size="lg"
								:preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']"
								:countries-height="50"
								:border-radius="8"
								v-model="whatsapp_code"
							/>
						</div>
					</div>
					<TextPlay
						class="mb-1"
						color="var(--fontcolor)"
						weight="semibold"
						:text="$t('settings.general.add_start_message')"
					/>
					<TextareaPlay
						id="textarea"
						maxlength="150"
						class="mb-3"
						v-model="whatsapp_message"
						:placeholder="$t('settings.general.write_your_message')"
						rows="3"
						max-rows="6"
					/>
				</div>
				<div class="d-flex align-items-center justify-content-between">
					<ButtonPlay
						@click="saveWhatsapp"
						:disabled="valid_whatsapp === false"
						type="normal"
						:text="$t('settings.general.save')"
					/>
					<TextPlay
						color="var(--fontcolor)"
						v-html="$t('settings.general.characters', { limit: whatsapp_message.length, total: 150 })"
					/>
				</div>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="smallcaps"
			:title="$t('settings.general.arq_fontsize')"
			:value="null"
			param="font"
			:toggle="false"
			:desc="$t('settings.general.arq_fontsize_desc')"
			:bottomDivider="false"
		>
			<div class="d-flex align-items-center mt-3">
				<b-form-input
					v-model="protected_font_size"
					@change="updateFontSize"
					style="width: 300px"
					variant="secondary"
					type="range"
					min="0"
					max="50"
					step="1"
				></b-form-input>
				<TextPlay
					class="ml-3"
					size="sm"
					color="var(--fontcolor)"
					v-html="$t('settings.general.fontsize', { size: protected_font_size })"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="grid-lock"
			:title="$t('settings.general.test_arq')"
			:value="null"
			param="protegido"
			:desc="$t('settings.general.test_arq_desc')"
			:toggle="false"
			pt="0px"
		>
			<div class="mt-3">
				<ButtonPlay
					@click="showModalUploadTeste"
					type="normal"
					:text="$t('settings.general.select_arq')"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="global"
			:title="$t('settings.general.domain')"
			:value="null"
			param="domain"
			:toggle="false"
			:desc="$t('settings.general.domain_desc')"
		>
			<div class="mt-3">
				<ButtonPlay
					@click="openModalConfigDominio"
					type="normal"
					:text="$t('settings.general.config_domain')"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="document-text"
			:title="$t('settings.general.use_terms')"
			:value="termsOfUseActivated"
			@change="update"
			param="termsOfUseActivated"
			:desc="$t('settings.general.use_terms_desc')"
		>
			<div
				v-if="termsOfUseActivated"
				class="mt-3"
			>
				<ButtonPlay
					@click="openModalConfigTermos"
					type="normal"
					:text="$t('settings.general.config_terms')"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			icon="mouse"
			:title="$t('settings.general.first_access')"
			:value="first_access_enabled"
			@change="update"
			param="first_access_enabled"
			:desc="$t('settings.general.first_access_desc')"
		>
			<div
				v-if="first_access_enabled"
				class="mt-3"
			>
				<ButtonPlay
					@click="openModalFirstAccess"
					type="normal"
					:text="$t('settings.general.config_first_access')"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="percentage-circle"
			:title="$t('settings.general.percentage_for_completion')"
			:value="limit_percetage_lesson"
			@change="update"
			param="limit_percetage_lesson"
			:desc="$t('settings.general.percentage_for_completion_desc')"
		>
			<div
				class="mt-3 d-flex align-items-center"
				v-if="limit_percetage_lesson"
			>
				<b-form-input
					v-model="number_limit_percetage_lesson"
					@change="updateLimitPercentageLessonAluno"
					style="width: 300px"
					variant="secondary"
					type="range"
					min="0"
					max="100"
					step="5"
				/>
				<TextPlay
					class="ml-3"
					size="sm"
					color="var(--fontcolor)"
					v-html="$t('settings.general.required_percentage', { percentage: number_limit_percetage_lesson })"
				/>
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			icon="video-tick"
			:title="$t('settings.general.next_btn')"
			:value="complete_lessson_next"
			@change="update"
			param="complete_lessson_next"
			:desc="$t('settings.general.next_btn_desc')"
		/>

		<SettingsItem
			icon="global-edit"
			v-if="renderComponent"
			:title="$t('settings.general.add_custom_links')"
			:value="null"
			param="domain"
			:toggle="false"
			:desc="$t('settings.general.add_custom_links_desc')"
		>
			<div class="mt-3">
				<ButtonPlay
					v-if="!expanded['custom-links']"
					@click="expand('custom-links')"
					type="normal"
					:text="$t('settings.general.config_custom_links')"
				/>
			</div>
			<div v-if="expanded['custom-links']">
				<InputCustomMenuLink index="1" />
				<InputCustomMenuLink index="2" />
				<InputCustomMenuLink index="3" />
				<InputCustomMenuLink index="4" />
			</div>
		</SettingsItem>

		<SettingsItem
			v-if="renderComponent"
			:title="$t('settings.general.add_links_for_terms')"
			:value="null"
			icon="link-1"
			param="domain"
			:toggle="false"
			:desc="$t('settings.general.add_links_for_terms_desc')"
		>
			<div class="mt-3">
				<div class="d-flex algin-items-center mb-3">
					<InputPlay
						w="400px"
						v-model="link_to_user_terms"
						type="text"
						:placeholder="$t('settings.general.use_terms')"
						@input="handleLinkToTermsOfUseInput"
					/>
					<ButtonPlay
						v-if="!hasLinkToUserTermsOnMount"
						class="ml-2"
						h="48px"
						type="normal"
						:text="$t('settings.general.save')"
						@click="saveText('link_to_user_terms')"
					/>
					<ButtonPlay
						v-else
						class="ml-2"
						h="48px"
						type="normal"
						:text="'Remover'"
						@click="handleDeleteLinkToUserTerms()"
					/>
				</div>

				<div class="d-flex algin-items-center">
					<InputPlay
						w="400px"
						v-model="link_to_privacy_policy"
						type="text"
						:placeholder="$t('settings.general.privacy_policy')"
						@input="handleLinkToPrivacyPolicyInput"
					/>
					<ButtonPlay
						v-if="!hasLinkToPrivacyPolicyOnMount"
						class="ml-2"
						h="48px"
						type="normal"
						:text="$t('settings.general.save')"
						@click="saveText('link_to_privacy_policy')"
					/>
					<ButtonPlay
						v-else
						class="ml-2"
						h="48px"
						type="normal"
						:text="'Remover'"
						@click="handleDeleteLinkToPrivacyPolicy()"
					/>
				</div>
			</div>
		</SettingsItem>

		<SettingsItem
			v-show="renderComponent"
			icon="google"
			iconPackage="custom"
			:title="$t('settings.general.tag_manager')"
			:value="google_gtm"
			@change="update"
			param="google_gtm"
			:desc="$t('settings.general.tag_manager_desc')"
		>
			<div
				class="mt-3 d-flex align-items-center"
				v-if="google_gtm"
			>
				<InputPlay
					v-model="google_gtm_code"
					type="text"
					w="400px"
					:placeholder="$t('settings.general.tag_manager_code')"
				/>
				<ButtonPlay
					:disabled="google_gtm_code?.length <= 0"
					class="ml-2"
					type="normal"
					h="48px"
					:text="$t('settings.general.save')"
					@click="saveText('google_gtm_code')"
				/>
			</div>
		</SettingsItem>

		<ModalUpload />
		<ModalDominio />
		<ModalTermosConfig />
		<ModalFirstAccessConfig />
	</div>
</template>

<script>
import isUrl from 'is-url';
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import axios from "axios";
import Cookies from "js-cookie";

import Locales from "./Locales.vue";

import ModalUpload from "@/components/ModalUpload.vue";
import ModalDominio from "@/components/configs/ModalDominio.vue";
import ModalTermosConfig from "@/components/configs/ModalTermosConfig.vue";
import ModalFirstAccessConfig from "@/components/configs/ModalFirstAccessConfig.vue";

import SettingsItem from "./SettingsItem.vue";
import InputCustomMenuLink from "./InputCustomMenuLink.vue";
import { mapActions } from "vuex";
import fp from "@/utils/fp";

export default {
	components: {
		VuePhoneNumberInput,
		ModalUpload,
		ModalDominio,
		ModalTermosConfig,
		ModalFirstAccessConfig,
		SettingsItem,
		Locales,
		InputCustomMenuLink,
	},
	computed: {
		remaining() {
			return 150 - this.whatsapp_message.length;
		},
	},
	data() {
		return {
			hasLinkToPrivacyPolicyOnMount: false,
			hasLinkToUserTermsOnMount: false,
			renderComponent: true,
			valid_whatsapp: false,
			rating_home: false,
			coment_lesson: false,
			comment_review: false,
			helper_data: false,
			external_help: false,
			limit_devices_per_member: false,
			jivochat: false,
			whatsapp: false,
			bitrix: false,
			termsOfUseActivated: false,
			first_access_enabled: false,
			limit_percetage_lesson: false,
			complete_lessson_next: false,
			google_gtm: false,
			google_gtm_code: "",
			number_limit_devices_per_member: 1,
			id_jivochat: "",
			id_whatsapp: "",
			whatsapp_code: "",
			whatsapp_number: "",
			whatsapp_message: this.$t("settings.txt67"),
			id_bitrix: "",
			protected_font_size: 40,
			number_limit_percetage_lesson: 1,
			external_help_link: "",
			link_to_user_terms: undefined,
			link_to_privacy_policy: undefined,
			showCustomLinksInput: false,
			expanded: {},
		};
	},
	methods: {
		...mapActions({
			actionSaveSettings: "config/actionSaveSettings",
			actionFetchSettings: "config/actionFetchSettings",
		}),
		handleDeleteLinkToPrivacyPolicy() {
			this.link_to_privacy_policy = "";
			this.saveText("link_to_privacy_policy");
			this.hasLinkToPrivacyPolicyOnMount = false;
		},
		handleDeleteLinkToUserTerms() {
			this.link_to_user_terms = "";
			this.saveText("link_to_user_terms");
			this.hasLinkToUserTermsOnMount = false;
		},
		onUpdate(value) {
			this.whatsapp_number = value.formattedNumber;
			this.valid_whatsapp = value.isValid;
		},
		handleLinkToPrivacyPolicyInput() {
			this.link_to_privacy_policy !== '' ? this.hasLinkToPrivacyPolicyOnMount = false : this.hasLinkToPrivacyPolicyOnMount = this.hasLinkToPrivacyPolicyOnMount
		},
		handleLinkToTermsOfUseInput() {
			this.link_to_user_terms !== '' ? this.hasLinkToUserTermsOnMount = false : this.hasLinkToUserTermsOnMount = this.hasLinkToUserTermsOnMount
		},
		async saveWhatsapp() {
			let data = {
				id: "whatsapp_number",
				value: this.whatsapp_number,
			};

			let data2 = {
				id: "whatsapp_message",
				value: this.whatsapp_message,
			};

			if (this.jivochat === false) {
				await Promise.all([serviceMeta.postID(data), serviceMeta.postID(data2)])
					.then(() => {
						this.$bvToast.toast(this.$t("settings.txt68"), {
							title: this.$t("settings.txt69"),
							variant: "success",
							autoHideDelay: 5000,
							appendToast: true,
						});
					})
					.finally(() => {
						setTimeout(() => {
							this.actionFetchSettings();
						}, 2000);
					});
			}
		},
		async getKeys(key) {
			var keys = [];
			if (key) {
				keys = [key];
			} else {
				keys = [
					"link_to_privacy_policy",
					"link_to_user_terms",
					"rating_home",
					"coment_lesson",
					"comment_review",
					"helper_data",
					"external_help",
					"external_help_link",
					"limit_devices_per_member",
					"jivochat",
					"whatsapp",
					"whatsapp_number",
					"whatsapp_message",
					"bitrix",
					"id_jivochat",
					"id_bitrix",
					"termsOfUseActivated",
					"first_access_enabled",
					"limit_percetage_lesson",
					"number_limit_percetage_lesson",
					"complete_lessson_next",
					"google_gtm",
					"google_gtm_code",
					"number_limit_devices_per_member",
					"protected_font_size",
					"pais",
				];
			}
			var result = "";
			for (let i = 0; i < keys.length; i++) {
				if (keys.length - 1 == i) {
					result += `keys[]=${keys[i]}`;
				} else {
					result += `keys[]=${keys[i]}&`;
				}
			}
			try {
				const resp = await serviceMeta.search(result);
				if (resp.link_to_privacy_policy !== undefined) {
						if (resp.link_to_privacy_policy !== null) {
							this.link_to_privacy_policy = resp.link_to_privacy_policy;
							if(this.link_to_privacy_policy !== null && this.link_to_privacy_policy !== undefined && this.link_to_privacy_policy !== ""){
								this.hasLinkToPrivacyPolicyOnMount = true;
							}
						}
					}
					if (resp.link_to_user_terms !== undefined) {
						if (resp.link_to_user_terms !== null) {
							this.link_to_user_terms = resp.link_to_user_terms;
							if(this.link_to_user_terms !== null && this.link_to_user_terms !== undefined && this.link_to_user_terms !== ""){
								this.hasLinkToUserTermsOnMount = true;
							}
						}
					}
					if (resp.rating_home !== undefined) {
						if (resp.rating_home === null || resp.rating_home === "on") {
							this.rating_home = true;
						} else {
							this.rating_home = false;
						}
					}
					if (resp.coment_lesson !== undefined) {
						if (resp.coment_lesson === "off") {
							this.coment_lesson = false;
							this.comment_review = false;
						} else {
							this.coment_lesson = true;
						}
					}
					if (resp.comment_review !== undefined) {
						if (resp.comment_review === "off") {
							this.comment_review = false;
						} else {
							this.comment_review = true;
						}
					}
					if (resp.helper_data !== undefined) {
						if (resp.helper_data === "off") {
							this.helper_data = false;
						} else {
							this.helper_data = true;
						}
					}
					if (resp.external_help !== undefined) {
						if (resp.external_help === "on") {
							this.external_help = true;
						} else {
							this.external_help = false;
						}
					}
					if (resp.external_help_link !== undefined) {
						if (resp.external_help_link) {
							this.external_help_link = resp.external_help_link;
						}
					}
					if (resp.limit_devices_per_member !== undefined) {
						if (resp.limit_devices_per_member === null || resp.limit_devices_per_member === "off") {
							this.limit_devices_per_member = false;
						} else {
							this.limit_devices_per_member = true;
						}
					}
					if (resp.number_limit_devices_per_member !== undefined) {
						if (resp.number_limit_devices_per_member !== null) {
							this.number_limit_devices_per_member = resp.number_limit_devices_per_member;
						} else {
							this.updateNumberLimitAluno(1);
						}
					}
					if (resp.jivochat !== undefined) {
						if (resp.jivochat === null || resp.jivochat === "off") {
							this.jivochat = false;
						} else {
							this.jivochat = true;
						}
					}
					if (resp.id_jivochat !== undefined) {
						if (resp.id_jivochat !== null) {
							this.id_jivochat = resp.id_jivochat;
						}
					}
					if (resp.whatsapp !== undefined) {
						if (resp.whatsapp === null || resp.whatsapp === "off") {
							this.whatsapp = false;
						} else {
							this.whatsapp = true;
						}
					}
					if (resp.whatsapp_code !== undefined) {
						if (resp.whatsapp_code !== null) {
							this.whatsapp_code = resp.whatsapp_code;
						}
					}
					if (resp.whatsapp_number !== undefined) {
						if (resp.whatsapp_number !== null) {
							this.whatsapp_code = resp.whatsapp_number;
							this.whatsapp_number = resp.whatsapp_number;
						}
					}
					if (resp.whatsapp_message !== undefined) {
						if (resp.whatsapp_message !== null) {
							this.whatsapp_message = resp.whatsapp_message;
						}
					}
					if (resp.bitrix !== undefined) {
						if (resp.bitrix === null || resp.bitrix === "off") {
							this.bitrix = false;
						} else {
							this.bitrix = true;
						}
					}
					if (resp.id_bitrix !== undefined) {
						if (resp.id_bitrix !== null) {
							this.id_bitrix = resp.id_bitrix.replace("https://cdn.bitrix24.com.br/", "");
						}
					}
					if (resp.termsOfUseActivated !== undefined) {
						if (resp.termsOfUseActivated === "on") {
							this.termsOfUseActivated = true;
						} else {
							this.termsOfUseActivated = false;
						}
					}
					if (resp.first_access_enabled !== undefined) {
						if (resp.first_access_enabled === "on") {
							this.first_access_enabled = true;
						} else {
							this.first_access_enabled = false;
						}
					}
					if (resp.limit_percetage_lesson !== undefined) {
						if (resp.limit_percetage_lesson === null || resp.limit_percetage_lesson === "off") {
							this.limit_percetage_lesson = false;
						} else {
							this.limit_percetage_lesson = true;
						}
					}
					if (resp.number_limit_percetage_lesson !== undefined) {
						if (resp.number_limit_percetage_lesson !== null) {
							this.number_limit_percetage_lesson = parseFloat(resp.number_limit_percetage_lesson);
						} else {
							this.updateLimitPercentageLessonAluno(1);
						}
					}
					if (resp.complete_lessson_next !== undefined) {
						if (resp.complete_lessson_next === null || resp.complete_lessson_next === "off") {
							this.complete_lessson_next = false;
						} else {
							this.complete_lessson_next = true;
						}
					}
					if (resp.google_gtm !== undefined) {
						if (resp.google_gtm === null || resp.google_gtm === "off") {
							this.google_gtm = false;
						} else {
							this.google_gtm = true;
						}
					}
					this.google_gtm_code = resp.google_gtm_code;
					if (resp.number_limit_devices_per_member !== undefined) {
						if (resp.number_limit_devices_per_member !== null) {
							this.number_limit_devices_per_member = parseFloat(resp.number_limit_devices_per_member);
						} else {
							this.updateNumberLimitAluno(1);
						}
					}
					if (resp.protected_font_size !== undefined) {
						if (resp.protected_font_size === null || resp.protected_font_size === "") {
							this.protected_font_size = 40;
						} else {
							this.protected_font_size = parseFloat(resp.protected_font_size);
						}
					}
			} catch (err) {
				console.log(err);
			} finally {
				this.$root.$emit("getCountry");
			}			
		},
		mensageUpdate() {
			this.$bvToast.toast(this.$t("settings.txt70"), {
				title: this.$t("settings.txt71"),
				variant: "success",
				autoHideDelay: 5000,
				appendToast: true,
			});
		},
		async update(item) {
			if (item[1] === "whatsapp") {
				if (this.jivochat === false && item[0] === true) {
					await this.getKeys();
				}

				if (this.jivochat === true && item[0] === true) {
					this.$bvToast.toast(this.$t("settings.txt72"), {
						title: this.$t("settings.txt73"),
						variant: "danger",
						autoHideDelay: 5000,
						appendToast: true,
					});
					this.forceRender();
					return;
				}
			}

			if (item[1] === "jivochat") {
				if (this.whatsapp === false && item[0] === true) {
					await this.getKeys();
				}

				if (this.whatsapp === true && item[0] === true) {
					this.$bvToast.toast(this.$t("settings.txt74"), {
						title: this.$t("settings.txt75"),
						variant: "danger",
						autoHideDelay: 5000,
						appendToast: true,
					});
					this.forceRender();
					return;
				}
				if (item[0] === false) {
					let container = document.querySelector("body > jdiv");
					if (container) {
						container.remove();
					}

					let jivo = document.querySelector("#jivo-iframe-container");
					if (jivo) {
						jivo.remove();
					}

					let scriptElement = document.getElementById("jivoscript");

					if (scriptElement) {
						scriptElement.parentNode.removeChild(scriptElement);
					}
				}
			}

			let data = {
				id: item[1],
				value: item[0] ? "on" : "off",
			};

			if (item[1] === "coment_lesson" && item[0] === false) {
				this.actionSaveSettings({
					id: "comment_review",
					value: "off",
				});
			}

			this.actionSaveSettings(data).then(() => {
				if (item[1] === "whatsapp" && item[0] === false) {
					this.$bvToast.toast(this.$t("settings.txt76"), {
						title: this.$t("settings.txt77"),
						variant: "info",
						autoHideDelay: 5000,
						appendToast: true,
					});
				} else if (item[1] === "jivochat" && item[0] === false) {
					this.$bvToast.toast(this.$t("settings.txt78"), {
						title: this.$t("settings.txt79"),
						variant: "info",
						autoHideDelay: 5000,
						appendToast: true,
					});
				} else {
					this.mensageUpdate();
				}

				this.getKeys(item[1]);

				if (item[1] === "whatsapp" && this.jivochat === false && item[0] === true) {
					this.$nextTick(() => {
						this.whatsapp_message = this.$t("settings.txt80");
					});
				}
			});
		},
		isValidURL(string) {
			try {
				new URL(string);
				return true;
			} catch (_) {
				return false;
			}
		},
		saveText(text) {
			if (text === "link_to_privacy_policy") {
				if (!isUrl(this.link_to_privacy_policy) && this.link_to_privacy_policy !== "") {
					this.$bvToast.toast(this.$t("settings.general.field_needs_to_be_url"), {
						title: this.$t("settings.general.error_save"),
						variant: "danger",
						autoHideDelay: 5000,
						appendToast: true,
					});
					return;
				}
			}

			if (text === "link_to_user_terms") {
				if (!isUrl(this.link_to_user_terms) && this.link_to_user_terms !== "") {
					this.$bvToast.toast(this.$t("settings.general.field_needs_to_be_url"), {
						title: this.$t("settings.general.error_save"),
						variant: "danger",
						autoHideDelay: 5000,
						appendToast: true,
					});
					return;
				}
			}

			if (text === "external_help_link") {
				if (!isUrl(this.external_help_link) && this.external_help_link !== "") {
					this.$bvToast.toast(this.$t("settings.general.field_needs_to_be_url"), {
						title: this.$t("settings.general.error_save"),
						variant: "danger",
						autoHideDelay: 5000,
						appendToast: true,
					});
					return;
				}
			}

			if (text) {
				let data = {
					id: text,
					value: this[text],
				};

				this.actionSaveSettings(data).then(() => {
					this.mensageUpdate();
					this.getKeys(text);
				});
			}

			if (text === "id_jivochat") {
				let data = {
					id: "id_jivochat",
					value: this.id_jivochat,
				};
				serviceMeta.postID(data).then(() => {
					this.mensageUpdate();
					this.getKeys("id_jivochat");
				});
			}
			if (text === "external_help_link") {
				let data = {
					id: "external_help_link",
					value: this.external_help_link,
				};
				serviceMeta.postID(data).then(() => {
					this.getKeys("external_help_link");
				});
			}
			if (text === "id_bitrix") {
				let data = {
					id: "id_bitrix",
					value: this.id_bitrix,
				};
				serviceMeta.postID(data).then(() => {
					this.mensageUpdate();
					this.getKeys("id_bitrix");
				});
			}
		},
		updateFontSize(number) {
			var data = {
				id: "protected_font_size",
				value: number,
			};
			serviceMeta.postID(data).then(() => {
				this.mensageUpdate();
				this.getKeys("protected_font_size");
			});
		},
		updateLimitPercentageLessonAluno(number) {
			let data = {
				id: "number_limit_percetage_lesson",
				value: number,
			};
			serviceMeta.postID(data).then(() => {
				this.mensageUpdate();
				this.getKeys("number_limit_percetage_lesson");
			});
		},
		updateNumberLimitAluno(number) {
			let data = {
				id: "number_limit_devices_per_member",
				value: number,
			};
			serviceMeta.postID(data).then(() => {
				this.mensageUpdate();
				this.getKeys("number_limit_devices_per_member");
			});
		},
		showModalUploadTeste() {
			this.$root.$emit("pdfteste");
			this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
		},
		openCustomLinks() {
			this.showCustomLinksInput = true;
		},
		openModalConfigDominio() {
			this.$root.$emit("bv::show::modal", "modal-dominio", "#btnShow");
		},
		openModalConfigTermos() {
			this.$root.$emit("bv::show::modal", "modal-config-termos", "#btnShow");
		},
		openModalFirstAccess() {
			this.$root.$emit("bv::show::modal", "modal-first-access-config", "#btnShow");
		},
		expand(section) {
			this.expanded = fp.set(section, true, this.expanded);
		},
		async forceRender() {
			this.renderComponent = false;
			await this.$nextTick();
			this.renderComponent = true;
		},
	},
	async mounted() {
		await this.getKeys();
		this.hasLinkToPrivacyPolicyOnMount = this.link_to_privacy_policy !== undefined && this.link_to_privacy_policy !== null && this.link_to_privacy_policy !== "";
		this.hasLinkToUserTermsOnMount = this.link_to_user_terms !== undefined && this.link_to_user_terms !== null && this.link_to_user_terms !== "";
		
		this.$root.$on("midiapdfteste", data => {
			axios({
				url: process.env.VUE_APP_API_HOST + `/media/${parseInt(data.id)}/preview`,
				method: "GET",
				responseType: "blob",
				headers: {
					Authorization: Cookies.get("auth_greennCourse"),
				},
			}).then(response => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const fileLink = document.createElement("a");
				fileLink.href = fileURL;
				fileLink.setAttribute("download", "Arquivo de Teste");
				document.body.appendChild(fileLink);
				fileLink.click();
			});
		});
	},
};
</script>

<style lang="scss">
.general {
	input[type="range"]::-webkit-slider-runnable-track {
		height: 8px !important;
		background: var(--input-border-color) !important;
		border: none !important;
		border-radius: 8px !important;
	}

	.country-selector__list {
		background-color: var(--multiselect-bg) !important;

		.dots-text {
			color: var(--fontcolor) !important;
			font-weight: 600 !important;
		}

		.selected {
			background-color: var(--multiselect-item-selected) !important;
		}

		.keyboard-selected {
			background-color: var(--multiselect-item-selected) !important;
		}
	}
}
</style>

<style lang="scss" scoped>
.general {
	&__title {
		margin-bottom: 32px;
	}
}
</style>
